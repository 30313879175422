import { ref } from "vue";

const excelDownloadLoader = ref(false);
const excelFileName = ref('');

export function useExcelDownloader() {


  return {
    excelFileName,
    excelDownloadLoader,
  }
}
